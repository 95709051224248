<template>
  <svg style="position: absolute; width: 0; height: 0; overflow: hidden">
    <clipPath id="checkbox" clipPathUnits="objectBoundingBox">
      <path d="M0,0.486 L0.1,0.343 L0.3,0.549 C0.474,0.285,0.7,0.095,0.95,0 L1,0.137 C1,0.137,0.522,0.41,0.292,1 L0,0.486"></path>
    </clipPath>

    <clipPath id="outer-border" clipPathUnits="objectBoundingBox"><path d="M0,0 C0,0.552,0.448,1,1,1 H0 V0"></path></clipPath>

    <clipPath id="outer-angle" clipPathUnits="objectBoundingBox">
      <path d="M1,1 C0.448,1,0,0.555,0,0.003 V1 H1"></path>
    </clipPath>

    <symbol id="search-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 42" fill="none">
      <path
        fill-rule="evenodd"
        d="M27.855 29.763c-2.929 2.238-6.628 3.574-10.648 3.574C7.704 33.336 0 25.874 0 16.668S7.704 0 17.207 0s17.207 7.463 17.207 16.668a16.2 16.2 0 0 1-2.433 8.55l15.08 11.088c1.392 1.024 1.634 2.898.54 4.186s-3.11 1.502-4.502.479L27.855 29.763zm-.324-13.094c0 5.523-4.622 10.001-10.324 10.001S6.883 22.191 6.883 16.668 11.505 6.667 17.207 6.667s10.324 4.478 10.324 10.001z"
        fill="#008522"
      />
    </symbol>

    <symbol id="logo" viewBox="0 0 841 276" fill="none">
      <path
        d="M0 70.84C0 31.68 28.51 0 63.65 0L776.77 1C811.84 1.05 840.26 32.75 840.26 71.83V146.14C840.26 185.26 811.8 216.97 776.69 216.97L689.31 217.58C675.06 217.58 663.51 230.45 663.51 246.32C663.51 262.19 652 275.07 637.72 275.07L63.57 274.47C28.46 274.47 0 242.75 0 203.63V70.84Z"
        fill="#008522"
      />
      <path
        d="M109.31 138H156.05C156.251 137.999 156.451 138.037 156.637 138.114C156.823 138.19 156.993 138.303 157.135 138.445C157.277 138.588 157.39 138.757 157.466 138.943C157.543 139.129 157.582 139.329 157.58 139.53V158C157.582 158.202 157.543 158.402 157.467 158.589C157.39 158.775 157.278 158.945 157.136 159.088C156.993 159.231 156.824 159.345 156.638 159.423C156.452 159.5 156.252 159.54 156.05 159.54H75.0002C74.7971 159.541 74.5958 159.502 74.4078 159.426C74.2198 159.349 74.0488 159.236 73.9048 159.093C73.7607 158.949 73.6464 158.779 73.5684 158.592C73.4904 158.404 73.4502 158.203 73.4502 158V138.57C73.455 138.202 73.5896 137.848 73.8302 137.57L113.5 91.93C113.691 91.7069 113.813 91.4341 113.854 91.1436C113.895 90.8531 113.852 90.557 113.73 90.2902C113.608 90.0234 113.413 89.7969 113.166 89.6374C112.92 89.478 112.634 89.3921 112.34 89.39H77.7902C77.5884 89.39 77.3887 89.3501 77.2024 89.2726C77.0161 89.1951 76.847 89.0815 76.7048 88.9384C76.5626 88.7952 76.4501 88.6254 76.3738 88.4386C76.2975 88.2518 76.2589 88.0518 76.2602 87.85V67.55C76.2562 67.347 76.2929 67.1453 76.3681 66.9568C76.4433 66.7682 76.5555 66.5966 76.6981 66.4522C76.8408 66.3077 77.0109 66.1932 77.1984 66.1156C77.386 66.0379 77.5872 65.9986 77.7902 66H154.18C154.382 65.9987 154.582 66.0373 154.769 66.1136C154.956 66.1899 155.125 66.3024 155.269 66.4446C155.412 66.5868 155.525 66.7559 155.603 66.9422C155.68 67.1285 155.72 67.3282 155.72 67.53V79.1C155.719 79.4666 155.588 79.8209 155.35 80.1L108.14 135.47C107.952 135.694 107.832 135.967 107.793 136.257C107.755 136.546 107.799 136.841 107.922 137.106C108.045 137.372 108.24 137.597 108.486 137.755C108.732 137.913 109.018 137.998 109.31 138V138Z"
        fill="#E0C7E6"
      />
      <path
        d="M260.58 128.46C260.822 128.461 261.06 128.518 261.276 128.627C261.492 128.736 261.68 128.894 261.825 129.088C261.969 129.282 262.066 129.507 262.109 129.745C262.152 129.983 262.138 130.228 262.07 130.46C255.85 149.9 238.07 162.46 213.3 162.46C182.45 162.46 162.45 142.64 162.45 112.73C162.45 82.82 182.83 63 213.11 63C247.67 63 268.7 88.74 258.73 118.22C258.613 118.517 258.409 118.771 258.145 118.949C257.881 119.128 257.569 119.222 257.25 119.22H195.72C195.493 119.22 195.268 119.271 195.062 119.368C194.856 119.465 194.675 119.607 194.53 119.783C194.386 119.959 194.282 120.165 194.227 120.386C194.172 120.606 194.166 120.837 194.21 121.06C196.46 131.33 202.92 137.17 214.05 137.17C221.53 137.17 226.97 134.46 229.89 129.17C230.019 128.925 230.211 128.719 230.447 128.575C230.683 128.43 230.954 128.353 231.23 128.35L260.58 128.46ZM196.25 102.46H227.56C227.957 102.466 228.339 102.316 228.627 102.043C228.915 101.77 229.085 101.396 229.1 101C229.31 92.81 223.72 87.3 213.1 87.3C203.86 87.3 197.54 91.7 194.76 100.49C194.689 100.721 194.674 100.966 194.715 101.204C194.757 101.442 194.855 101.667 195 101.86C195.145 102.053 195.334 102.209 195.552 102.315C195.769 102.421 196.008 102.474 196.25 102.47V102.46Z"
        fill="#E0C7E6"
      />
      <path
        d="M358 94.25C357.961 102.334 356.192 110.317 352.81 117.66C352.685 117.928 352.486 118.154 352.236 118.312C351.986 118.47 351.696 118.553 351.4 118.55H324.09C323.827 118.547 323.569 118.477 323.341 118.346C323.112 118.215 322.921 118.028 322.786 117.802C322.651 117.577 322.575 117.32 322.567 117.057C322.559 116.794 322.619 116.533 322.74 116.3C325.74 110.3 327.54 105.04 327.54 100.04C327.54 92.94 323.99 88.26 318.19 88.26C309.97 88.26 303.8 97.99 303.8 113.13V158C303.8 158.202 303.76 158.402 303.683 158.589C303.605 158.776 303.492 158.946 303.349 159.089C303.206 159.232 303.036 159.345 302.849 159.423C302.663 159.5 302.462 159.54 302.26 159.54H275.42C275.012 159.54 274.62 159.378 274.331 159.089C274.042 158.8 273.88 158.408 273.88 158V67.55C273.877 67.3465 273.915 67.1446 273.992 66.956C274.068 66.7674 274.181 66.5959 274.325 66.4516C274.468 66.3073 274.639 66.193 274.827 66.1154C275.015 66.0379 275.217 65.9986 275.42 66H302.26C302.462 65.9987 302.662 66.0373 302.849 66.1136C303.035 66.1899 303.205 66.3024 303.348 66.4446C303.492 66.5868 303.605 66.7559 303.683 66.9422C303.76 67.1284 303.8 67.3282 303.8 67.53V75C303.8 75.3342 303.91 75.6592 304.112 75.9253C304.314 76.1914 304.598 76.384 304.92 76.4736C305.242 76.5631 305.584 76.5448 305.895 76.4214C306.206 76.2979 306.467 76.0762 306.64 75.79C311.75 67.79 320.06 63.08 330.53 63.08C347 63 358 75 358 94.25Z"
        fill="#E0C7E6"
      />
      <path
        d="M363.63 112.76C363.63 82.84 384 63 414.48 63C444.96 63 465.34 82.81 465.34 112.73C465.34 142.65 445 162.49 414.48 162.49C383.96 162.49 363.63 142.67 363.63 112.76ZM434.49 112.76C434.49 97.76 426.49 88.26 414.49 88.26C402.49 88.26 394.49 97.8 394.49 112.76C394.49 127.53 402.49 137.25 414.49 137.25C426.49 137.25 434.49 127.53 434.49 112.76Z"
        fill="#E0C7E6"
      />
      <path
        d="M566.3 105.09V158C566.301 158.202 566.262 158.402 566.186 158.589C566.11 158.775 565.997 158.945 565.855 159.088C565.713 159.231 565.544 159.345 565.358 159.423C565.171 159.5 564.971 159.54 564.77 159.54H537.92C537.513 159.537 537.124 159.374 536.837 159.085C536.551 158.797 536.39 158.407 536.39 158V105.09C536.39 98.36 536.39 87.09 523.86 87.09C513.77 87.09 506.47 97.56 506.47 113.09V158C506.471 158.202 506.432 158.402 506.356 158.589C506.28 158.775 506.167 158.945 506.025 159.088C505.883 159.231 505.714 159.345 505.528 159.423C505.341 159.5 505.141 159.54 504.94 159.54H478.1C477.691 159.54 477.3 159.378 477.011 159.089C476.722 158.8 476.56 158.408 476.56 158V67.55C476.557 67.3466 476.595 67.1446 476.671 66.956C476.748 66.7674 476.861 66.5959 477.004 66.4516C477.148 66.3073 477.318 66.193 477.506 66.1154C477.695 66.0379 477.896 65.9987 478.1 66H504.94C505.141 65.9987 505.341 66.0373 505.527 66.1138C505.713 66.1902 505.882 66.3028 506.025 66.4452C506.167 66.5875 506.28 66.7567 506.356 66.9429C506.432 67.1292 506.471 67.3287 506.47 67.53V75.6C506.472 75.9299 506.582 76.2502 506.781 76.5129C506.981 76.7757 507.26 76.9669 507.577 77.0579C507.894 77.149 508.233 77.1352 508.541 77.0184C508.85 76.9016 509.112 76.6882 509.29 76.41C514.79 68 524.14 63 535.83 63C566.3 63 566.3 93.69 566.3 105.09Z"
        fill="#E0C7E6"
      />
      <path
        d="M674.72 128.46C674.962 128.459 675.202 128.515 675.419 128.623C675.636 128.732 675.825 128.889 675.97 129.084C676.114 129.279 676.212 129.504 676.253 129.743C676.295 129.982 676.28 130.228 676.21 130.46C670 149.9 652.21 162.46 627.44 162.46C596.59 162.46 576.59 142.64 576.59 112.73C576.59 82.82 597 63 627.26 63C661.81 63 682.85 88.74 672.87 118.22C672.753 118.517 672.549 118.771 672.285 118.949C672.021 119.128 671.709 119.222 671.39 119.22H609.86C609.632 119.22 609.407 119.271 609.202 119.368C608.996 119.465 608.814 119.607 608.67 119.783C608.525 119.959 608.422 120.165 608.367 120.386C608.311 120.606 608.305 120.837 608.35 121.06C610.6 131.33 617.06 137.17 628.19 137.17C635.67 137.17 641.11 134.46 644.03 129.17C644.159 128.926 644.352 128.721 644.587 128.576C644.823 128.432 645.093 128.354 645.37 128.35L674.72 128.46ZM610.4 102.46H641.7C642.093 102.466 642.473 102.319 642.76 102.05C643.047 101.782 643.219 101.413 643.24 101.02C643.45 92.83 637.86 87.32 627.24 87.32C617.99 87.32 611.67 91.72 608.9 100.51C608.83 100.741 608.816 100.985 608.859 101.222C608.902 101.459 609.001 101.683 609.147 101.874C609.294 102.066 609.484 102.22 609.702 102.323C609.92 102.427 610.159 102.477 610.4 102.47V102.46Z"
        fill="#E0C7E6"
      />
      <path
        d="M754.36 91.26H730.36C730.158 91.2587 729.958 91.2973 729.771 91.3736C729.584 91.4499 729.414 91.5624 729.271 91.7046C729.128 91.8468 729.014 92.0159 728.937 92.2022C728.859 92.3885 728.82 92.5882 728.82 92.79V127C728.82 132.79 731.07 137.28 739.67 137.28C744.38 137.28 747.67 135.96 751.56 133.65C751.792 133.513 752.057 133.44 752.326 133.438C752.596 133.436 752.862 133.504 753.097 133.637C753.332 133.769 753.528 133.962 753.665 134.194C753.802 134.426 753.876 134.69 753.88 134.96V156.96C753.883 157.229 753.815 157.495 753.683 157.73C753.552 157.965 753.361 158.162 753.13 158.3C748.5 161.01 742.02 162.52 733.68 162.52C702.27 162.52 698.909 140.83 698.909 127V92.79C698.909 92.5882 698.87 92.3885 698.792 92.2022C698.715 92.0159 698.601 91.8468 698.458 91.7046C698.315 91.5624 698.145 91.4499 697.958 91.3736C697.771 91.2973 697.571 91.2587 697.37 91.26H681.15C680.741 91.26 680.349 91.0978 680.061 90.8089C679.772 90.5201 679.61 90.1284 679.61 89.72V67.55C679.607 67.3466 679.645 67.1446 679.721 66.956C679.797 66.7674 679.911 66.5959 680.054 66.4516C680.197 66.3073 680.368 66.193 680.556 66.1154C680.744 66.0379 680.946 65.9987 681.15 66H697.33C697.738 66 698.13 65.8377 698.418 65.5489C698.707 65.2601 698.87 64.8684 698.87 64.46V41.56C698.867 41.3569 698.904 41.1553 698.98 40.9668C699.056 40.7783 699.168 40.6066 699.31 40.4616C699.452 40.3167 699.622 40.2012 699.809 40.122C699.996 40.0428 700.196 40.0013 700.4 40H727.25C727.655 40 728.044 40.1612 728.331 40.4481C728.618 40.7351 728.78 41.1242 728.78 41.53V64.48C728.785 64.885 728.949 65.2715 729.238 65.556C729.526 65.8405 729.915 66 730.32 66H754.32C754.521 65.9987 754.72 66.0373 754.907 66.1138C755.093 66.1902 755.262 66.3028 755.404 66.4452C755.547 66.5875 755.659 66.7567 755.736 66.9429C755.812 67.1292 755.851 67.3287 755.849 67.53V89.72C755.852 90.1207 755.698 90.5064 755.419 90.7944C755.141 91.0824 754.76 91.2495 754.36 91.26Z"
        fill="#E0C7E6"
      />
      <path
        d="M97.7597 210.21V225.65H91.4697V210.38C91.4697 207.23 91.4697 202.77 86.6197 202.77C82.4297 202.77 79.4997 206.52 79.4997 212.14V225.65H73.2197V198.08H79.4997V203.15C80.3361 201.378 81.6658 199.884 83.3295 198.848C84.9931 197.812 86.9202 197.278 88.8797 197.31C97.7597 197.31 97.7597 206.08 97.7597 210.21Z"
        fill="#E0C7E6"
      />
      <path
        d="M127.1 210V225.66H121.31V220.86C120.332 222.616 118.886 224.066 117.133 225.049C115.38 226.032 113.389 226.51 111.38 226.43C105.54 226.43 101.9 223.02 101.9 218.22C101.9 212.65 106.75 209.28 114.52 209.28C116.637 209.29 118.746 209.531 120.81 210C120.81 205.81 119.32 202.78 114.91 202.78C111.6 202.78 109.56 204.43 110.22 207.52H103.88C102.39 201.4 107.8 197.32 114.97 197.32C123 197.31 127.1 202.33 127.1 210ZM121.03 214.8C119.091 214.179 117.067 213.862 115.03 213.86C110.51 213.86 108.3 215.52 108.3 218.05C108.3 220.26 109.96 221.42 112.66 221.42C114.596 221.422 116.474 220.767 117.989 219.563C119.504 218.359 120.566 216.676 121 214.79L121.03 214.8Z"
        fill="#E0C7E6"
      />
      <path
        d="M149.27 203.54H141.38V216.94C141.38 219.2 141.93 220.94 144.97 220.94C146.265 220.926 147.535 220.582 148.66 219.94V225.45C147.121 226.151 145.442 226.493 143.75 226.45C135.54 226.45 135.1 220 135.1 217.02V203.54H129.86V198.08H135.1V190.75H141.38V198.08H149.27V203.54Z"
        fill="#E0C7E6"
      />
      <path
        d="M170.83 198.08H177.12V225.65H170.83V220.65C170.041 222.348 168.779 223.782 167.194 224.779C165.61 225.776 163.771 226.294 161.9 226.27C154.23 226.27 153.02 218.66 153.02 213.76V198.08H159.31V213.74C159.31 217.38 160.13 220.85 164.1 220.85C168.07 220.85 170.83 217.22 170.83 212.14V198.08Z"
        fill="#E0C7E6"
      />
      <path
        d="M204.14 205.25C204.131 207.199 203.68 209.121 202.82 210.87H196.25C197.091 209.588 197.569 208.102 197.63 206.57C197.63 204.37 196.42 202.88 194.49 202.88C191.49 202.88 189.42 206.57 189.42 212.14V225.65H183.13V198.08H189.42V202.77C190.91 199.35 193.55 197.31 196.97 197.31C201.33 197.31 204.14 200.62 204.14 205.25Z"
        fill="#E0C7E6"
      />
      <path
        d="M231.22 210V225.66H225.43V220.86C224.451 222.616 223.005 224.066 221.252 225.049C219.499 226.032 217.508 226.51 215.5 226.43C209.66 226.43 206.02 223.02 206.02 218.22C206.02 212.65 210.87 209.28 218.65 209.28C220.763 209.29 222.869 209.531 224.93 210C224.93 205.81 223.44 202.78 219.03 202.78C215.72 202.78 213.68 204.43 214.34 207.52H208C206.51 201.4 211.92 197.32 219.09 197.32C227.14 197.31 231.22 202.33 231.22 210ZM225.15 214.8C223.21 214.179 221.186 213.862 219.15 213.86C214.63 213.86 212.42 215.52 212.42 218.05C212.42 220.26 214.08 221.42 216.78 221.42C218.72 221.431 220.607 220.78 222.128 219.574C223.65 218.369 224.715 216.682 225.15 214.79V214.8Z"
        fill="#E0C7E6"
      />
      <path d="M237 225.65V187.05H243.29V225.65H237Z" fill="#E0C7E6" />
      <path d="M249.86 225.65V187.05H256.15V225.65H249.86Z" fill="#E0C7E6" />
      <path
        d="M280.25 198.08H286.81L276.72 227.75C275.28 232.05 272.47 237.29 265.58 237.29C264.039 237.308 262.508 237.047 261.06 236.52V231.06C262.293 231.536 263.598 231.797 264.92 231.83C268.39 231.83 269.92 229.46 270.76 227.2L259.68 198.08H266.35L273.57 219.64L280.25 198.08Z"
        fill="#E0C7E6"
      />
      <path
        d="M316.53 195.05C317.25 192.9 316.75 191.52 315.26 191.52C313.77 191.52 313.06 192.79 313.06 194.88V198.08H322.06V203.54H313.06V225.65H306.77V203.54H301.53V198.08H306.77V194.88C306.77 189.48 309.86 186.28 315.37 186.28C321.22 186.28 323.48 189.92 322.05 195.05H316.53Z"
        fill="#E0C7E6"
      />
      <path
        d="M348.52 210V225.66H342.73V220.86C341.754 222.617 340.308 224.069 338.555 225.052C336.801 226.036 334.809 226.513 332.8 226.43C326.96 226.43 323.32 223.02 323.32 218.22C323.32 212.65 328.17 209.28 335.95 209.28C338.064 209.29 340.17 209.531 342.23 210C342.23 205.81 340.74 202.78 336.33 202.78C333.02 202.78 330.98 204.43 331.64 207.52H325.3C323.81 201.4 329.22 197.32 336.39 197.32C344.44 197.31 348.52 202.33 348.52 210ZM342.45 214.8C340.511 214.179 338.487 213.862 336.45 213.86C331.93 213.86 329.72 215.52 329.72 218.05C329.72 220.26 331.38 221.42 334.08 221.42C336.022 221.432 337.909 220.782 339.431 219.577C340.953 218.371 342.018 216.683 342.45 214.79V214.8Z"
        fill="#E0C7E6"
      />
      <path
        d="M352.66 215.78H358.72C358.34 219.2 360.87 221.35 365.17 221.35C368.54 221.35 370.8 220.03 370.8 218.04C370.8 212.53 353.04 216.5 353.04 206.19C353.04 200.67 358.17 197.31 364.84 197.31C372.23 197.31 377.58 201.44 376.42 207.67H370.3C371.08 204.53 368.59 202.38 364.79 202.38C361.59 202.38 359.5 203.81 359.5 205.8C359.5 211.37 377.31 207.12 377.31 217.6C377.31 223.17 372.31 226.42 365.12 226.42C357.34 226.42 352.22 222.45 352.66 215.78Z"
        fill="#E0C7E6"
      />
      <path
        d="M398.6 203.54H390.71V216.94C390.71 219.2 391.26 220.94 394.29 220.94C395.588 220.927 396.862 220.583 397.99 219.94V225.45C396.45 226.151 394.771 226.493 393.08 226.45C384.86 226.45 384.42 220 384.42 217.02V203.54H379.18V198.08H384.42V190.75H390.71V198.08H398.6V203.54Z"
        fill="#E0C7E6"
      />
      <path
        d="M415.53 190.08C415.53 189.289 415.765 188.516 416.204 187.858C416.644 187.2 417.269 186.687 418 186.384C418.73 186.082 419.535 186.003 420.311 186.157C421.087 186.311 421.799 186.692 422.359 187.252C422.918 187.811 423.299 188.524 423.453 189.3C423.608 190.076 423.529 190.88 423.226 191.611C422.923 192.342 422.41 192.966 421.753 193.406C421.095 193.845 420.321 194.08 419.53 194.08C418.469 194.08 417.452 193.659 416.702 192.908C415.952 192.158 415.53 191.141 415.53 190.08ZM416.35 225.65V198.08H422.64V225.65H416.35Z"
        fill="#E0C7E6"
      />
      <path
        d="M453.47 210.21V225.65H447.18V210.38C447.18 207.23 447.18 202.77 442.33 202.77C438.14 202.77 435.21 206.52 435.21 212.14V225.65H428.93V198.08H435.21V203.15C436.046 201.378 437.376 199.884 439.039 198.848C440.703 197.812 442.63 197.278 444.59 197.31C453.47 197.31 453.47 206.08 453.47 210.21Z"
        fill="#E0C7E6"
      />
      <path
        d="M475.64 203.54H467.75V216.94C467.75 219.2 468.3 220.94 471.34 220.94C472.636 220.926 473.906 220.582 475.03 219.94V225.45C473.491 226.151 471.812 226.493 470.12 226.45C461.91 226.45 461.47 220 461.47 217.02V203.54H456.23V198.08H461.47V190.75H467.75V198.08H475.64V203.54Z"
        fill="#E0C7E6"
      />
      <path
        d="M506 216.94C504.4 222.73 499.5 226.42 492.6 226.42C484 226.42 478.54 220.63 478.54 211.87C478.54 203.11 484.06 197.31 492.77 197.31C502.47 197.31 507.49 204.59 504.85 213.63H485.23C485.79 218.21 488.32 220.91 492.73 220.91C495.82 220.91 498.25 219.48 499.19 216.91L506 216.94ZM485.32 209.16H498.89C499.28 205.16 497.02 202.6 492.66 202.6C488.54 202.6 486 204.92 485.34 209.16H485.32Z"
        fill="#E0C7E6"
      />
      <path
        d="M531.78 205.25C531.771 207.199 531.319 209.121 530.46 210.87H523.89C524.731 209.588 525.208 208.102 525.27 206.57C525.27 204.37 524.06 202.88 522.13 202.88C519.13 202.88 517.06 206.57 517.06 212.14V225.65H510.77V198.08H517.06V202.77C518.54 199.35 521.19 197.31 524.61 197.31C529 197.31 531.78 200.62 531.78 205.25Z"
        fill="#E0C7E6"
      />
      <path
        d="M560.46 210.21V225.65H554.17V210.38C554.17 207.23 554.17 202.77 549.32 202.77C545.13 202.77 542.2 206.52 542.2 212.14V225.65H535.92V198.08H542.2V203.15C543.036 201.378 544.366 199.884 546.03 198.848C547.693 197.812 549.62 197.278 551.58 197.31C560.46 197.31 560.46 206.08 560.46 210.21Z"
        fill="#E0C7E6"
      />
      <path
        d="M592.22 216.94C590.62 222.73 585.71 226.42 578.82 226.42C570.22 226.42 564.76 220.63 564.76 211.87C564.76 203.11 570.27 197.31 578.99 197.31C588.69 197.31 593.71 204.59 591.06 213.63H571.43C571.98 218.21 574.52 220.91 578.93 220.91C582.02 220.91 584.45 219.48 585.38 216.91L592.22 216.94ZM571.54 209.16H585.11C585.49 205.16 583.23 202.6 578.88 202.6C574.74 202.6 572.2 204.92 571.54 209.16V209.16Z"
        fill="#E0C7E6"
      />
      <path
        d="M613.46 203.54H605.57V216.94C605.57 219.2 606.12 220.94 609.16 220.94C610.455 220.926 611.725 220.582 612.85 219.94V225.45C611.31 226.151 609.631 226.493 607.94 226.45C599.73 226.45 599.28 220 599.28 217.02V203.54H594.05V198.08H599.28V190.75H605.57V198.08H613.46V203.54Z"
        fill="#E0C7E6"
      />
    </symbol>

    <symbol id="user" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
    </symbol>

    <symbol id="plus" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 3V13" stroke="#008522" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13 8.01807L3.00002 7.98188" stroke="#008522" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </symbol>

    <symbol id="angle-right" viewBox="0 0 26 46" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.26561 1.78688L24.3575 20.8788C25.5291 22.0503 25.5291 23.9498 24.3575 25.1214L5.26561 44.2133C4.09403 45.3849 2.19454 45.3849 1.02297 44.2133C-0.148606 43.0417 -0.148606 41.1422 1.02297 39.9706L17.9935 23.0001L1.02297 6.02952C-0.148606 4.85795 -0.148606 2.95846 1.02297 1.78688C2.19454 0.61531 4.09403 0.61531 5.26561 1.78688Z"
        fill="#008522"
      />
    </symbol>

    <symbol id="facebook" viewBox="0 0 77 77" fill="currentColor">
      <path
        d="M77 38.5C77 17.237 59.763 0 38.5 0C17.237 0 0 17.237 0 38.5C0 57.7162 14.0788 73.644 32.4844 76.5323V49.6289H22.709V38.5H32.4844V30.018C32.4844 20.3689 38.2323 15.0391 47.0264 15.0391C51.2373 15.0391 55.6445 15.791 55.6445 15.791V25.2656H50.7899C46.0075 25.2656 44.5156 28.2336 44.5156 31.2812V38.5H55.1934L53.4864 49.6289H44.5156V76.5323C62.9212 73.644 77 57.7162 77 38.5Z"
      />
    </symbol>

    <symbol id="close-icon" viewBox="0 0 29 30" fill="none">
      <line x1="2.82842" y1="3.01467" x2="26.9961" y2="27.1162" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
      <line x1="26.9814" y1="2.82843" x2="2.84683" y2="26.963" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
    </symbol>

    <symbol id="instagram" viewBox="0 0 77 77" fill="currentColor">
      <path
        d="M38.5 6.933c10.287 0 11.505.045 15.55.226 3.76.165 5.79.797 7.143 1.323 1.79.692 3.083 1.534 4.421 2.872a11.84 11.84 0 0 1 2.873 4.422c.526 1.354 1.158 3.399 1.323 7.143.18 4.061.226 5.279.226 15.55l-.226 15.55c-.165 3.76-.797 5.79-1.323 7.144-.692 1.79-1.534 3.083-2.873 4.422-1.353 1.353-2.632 2.181-4.421 2.872-1.353.526-3.399 1.158-7.143 1.323-4.061.18-5.279.226-15.55.226-10.287 0-11.505-.045-15.55-.226-3.76-.165-5.79-.797-7.143-1.323-1.79-.692-3.083-1.534-4.421-2.872a11.84 11.84 0 0 1-2.872-4.422c-.526-1.353-1.158-3.399-1.323-7.144-.18-4.06-.226-5.279-.226-15.55l.226-15.55c.165-3.76.797-5.79 1.323-7.143.692-1.79 1.534-3.083 2.872-4.421 1.353-1.354 2.632-2.181 4.422-2.872 1.354-.526 3.399-1.158 7.143-1.323 4.046-.18 5.264-.226 15.55-.226zM38.5 0C28.048 0 26.74.045 22.634.226c-4.091.18-6.903.842-9.339 1.79a18.79 18.79 0 0 0-6.828 4.452 18.86 18.86 0 0 0-4.452 6.813C1.068 15.732.406 18.53.225 22.62.045 26.74 0 28.048 0 38.5l.226 15.866c.18 4.091.842 6.903 1.79 9.339a18.79 18.79 0 0 0 4.452 6.828 18.82 18.82 0 0 0 6.813 4.437c2.451.947 5.249 1.609 9.339 1.79 4.106.18 5.414.226 15.866.226l15.866-.226c4.091-.18 6.903-.842 9.339-1.79a18.82 18.82 0 0 0 6.813-4.437 18.82 18.82 0 0 0 4.437-6.813c.947-2.451 1.609-5.249 1.79-9.339.181-4.106.226-5.414.226-15.866l-.226-15.866c-.18-4.091-.842-6.903-1.79-9.339a18.03 18.03 0 0 0-4.407-6.843 18.81 18.81 0 0 0-6.813-4.437C61.27 1.083 58.472.421 54.382.24 50.261.045 48.952 0 38.5 0zm0 18.724c-10.918 0-19.776 8.858-19.776 19.776S27.582 58.276 38.5 58.276 58.276 49.418 58.276 38.5 49.418 18.724 38.5 18.724zm0 32.605c-7.083 0-12.828-5.745-12.828-12.828S31.417 25.672 38.5 25.672 51.328 31.417 51.328 38.5 45.583 51.328 38.5 51.328zm25.175-33.387a4.62 4.62 0 0 1-4.617 4.617 4.62 4.62 0 0 1-4.617-4.617 4.62 4.62 0 0 1 4.617-4.617 4.63 4.63 0 0 1 4.617 4.617z"
      />
    </symbol>
  </svg>
</template>
<script setup lang="ts"></script>
